<template>
  <v-container fluid>
    <Loader v-if="isLoading" />
    <v-row>
      <h1>Client Modify</h1>
      <v-spacer></v-spacer>
      <CopyButton :data="customerId" :text="customerId" />
    </v-row>
    <v-form
      v-if="!isLoading"
      class="client-form"
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <!--
  -------------------------------------------------------
          General informaion
-------------------------------------------------------
         -->

      <v-divider class="divider" />
      <h2>General informaion</h2>
      <v-row>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="form.name"
            placeholder="IG name shows on post/story widgets"
            label="Instagram name*"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="form.personal_name"
            placeholder="Shows in sms message and export page"
            label="First Name*"
            required
          ></v-text-field>
        </v-col>
        <v-col data-app cols="12" sm="4">
          <v-text-field
            v-model="form.domain"
            label="Domain"
            required
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="form.primary_phone"
            placeholder="13049060514"
            label="Primary Phone*"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="form.mail"
            placeholder="E-mail"
            label="E-mail*"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="form.business_email"
            placeholder="Business E-mail"
            label="Business E-mail"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="form.website"
            placeholder="Website"
            label="Website"
          ></v-text-field>
        </v-col>
        <v-col data-app cols="12" sm="4">
          <v-select
            v-model="form.send_by"
            :items="sendByiItems"
            item-text="name"
            item-value="name"
            label="Send By*"
            validate-on-blur
            lazy-validation
          ></v-select>
        </v-col>
        <v-col data-app cols="12" sm="4">
          <v-select
            v-model="form.postAnyway"
            :items="postAnyWayList"
            item-text="name"
            item-value="id"
            label="Post Away?"
          ></v-select>
        </v-col>
        <v-col data-app cols="12" sm="4">
          <v-select
            v-model="form.owner_id"
            :items="itemsSmm"
            item-text="name"
            item-value="id"
            label="Owner*"
          ></v-select>
        </v-col>
        <v-col data-app cols="12" sm="4">
          <v-select
            multiple
            v-model="form.smm_id"
            :items="itemsSmm"
            item-text="name"
            item-value="id"
            label="SMM*"
            validate-on-blur
            lazy-validation
          ></v-select>
        </v-col>

        <v-col cols="12" sm="4">
          <v-select
            multiple
            v-model="form.social_platforms"
            :items="supportedSocialPlatforms"
            item-text="name"
            item-value="name"
            label="Social Platforms"
            validate-on-blur
            lazy-validation
          ></v-select>
        </v-col>
        <v-col data-app cols="12" sm="4">
          <v-select
            v-model="form.time_zone"
            :items="timeZones"
            item-text="name"
            item-value="id"
            label="Time Zone*"
            validate-on-blur
            lazy-validation
          ></v-select>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="form.ig_page"
            placeholder="IG page link"
            label="IG page link"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="form.fb_page"
            placeholder="Facebook page"
            label="Facebook page"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="form.address_country"
            placeholder="Country"
            label="Country"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="form.address_state"
            placeholder="State"
            label="State"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="form.address_city"
            placeholder="City"
            label="City"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="form.address_street"
            placeholder="Street"
            label="Street"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <div class="form-control additional_phones">
            <span class="form-control__name">Additional phones</span>
            <v-combobox
              v-model="form.additional_phones"
              chips
              hide-details
              class="tags_container"
              label="Additional Phones"
              multiple
              append-icon=""
              solo
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="deleteAdditionalPhone(item)"
                >
                  <strong>{{ item }}</strong>
                </v-chip>
              </template>
            </v-combobox>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="form-control additional_phones">
            <span class="form-control__name">Additional emails</span>
            <v-combobox
              v-model="form.additional_emails"
              chips
              class="tags_container additional-emails"
              label="Additional Emails"
              multiple
              append-icon=""
              solo
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  :class="{ error: rules.invalidChipEmail(item) }"
                  close
                  @click="select"
                  @click:close="deleteAdditionalEmail(item)"
                >
                  <strong>{{ item }}</strong>
                </v-chip>
              </template>
            </v-combobox>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            v-model="form.slack_email"
            placeholder="Slack E-mail"
            label="Slack E-mail"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            v-model="form.profession"
            placeholder="Profession"
            label="Profession"
          ></v-text-field>
        </v-col>
        <v-col data-app cols="12" sm="4">
          <v-checkbox
            color="#7e5689"
            v-model="form.is_atproperties"
            label="@Properties"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            v-model="form.media_mode"
            :items="mediaModeList"
            item-text="name"
            item-value="value"
            label="Media Mode"
            :clearable="false"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="4">
          <v-select
            v-model="form.residence"
            :items="residenceList"
            item-text="name"
            item-value="value"
            label="Residence"
            :clearable="false"
          ></v-select>
        </v-col>

        <v-col data-app cols="12" sm="12">
          <v-text-field
            v-model="form.about_client"
            placeholder="About Client"
            label="About Client"
          ></v-text-field>
        </v-col>

        <v-col data-app cols="12" sm="12">
          <v-text-field
            v-model="form.about_business"
            placeholder="About Business"
            label="About Business"
          ></v-text-field>
        </v-col>

        <v-col data-app cols="12" sm="12">
          <v-text-field
            v-model="form.permanent_export_note"
            placeholder="Permanent Export Note"
            label="Permanent Export Note"
          ></v-text-field>
        </v-col>

        <v-col data-app cols="12" sm="6">
          <FilesSync
            :initialFilesSync="form.files_sync"
            :customerId="customerId"
            @modifiedFilesSync="
              form.files_sync = $event;
              formInitial.files_sync = $event;
            "
          />
        </v-col>
      </v-row>
      <!--
  -------------------------------------------------------
          Subscription
-------------------------------------------------------
         -->
      <v-divider class="divider" />

      <h2>Subscription</h2>
      <v-row>
        <v-col data-app cols="12" sm="4">
          <v-checkbox
            color="#7e5689"
            v-model="form.rush_post"
            label="Rush Post"
          ></v-checkbox
        ></v-col>
        <v-col data-app cols="12" sm="4">
          <v-select
            item-text="name"
            :value="form.activity_state"
            item-value="name"
            :items="
              ['Testing', 'Canceled'].includes(form.activity_state)
                ? activityStateList
                : activityStateList.filter((i) => i.name !== 'Canceled')
            "
            label="Subscription Status*"
            validate-on-blur
            lazy-validation
            @change="activityStateChanged($event)"
          ></v-select>
        </v-col>
        <v-col data-app cols="12" sm="4">
          <v-text-field
            v-model="form.stripe_customer_id"
            placeholder="Unallocated"
            label="Stripe customer id"
            required
            readonly
          ></v-text-field>
        </v-col>
        <v-col v-if="isCancellationFlow" data-app cols="12" sm="4">
          <v-select
            v-model="form.churn_type"
            :items="['Pre-Activation', 'Pre-Payment', 'Post-Payment']"
            label="Churn Type*"
            validate-on-blur
            lazy-validation
          ></v-select>
        </v-col>
        <v-col v-if="isCancellationFlow" data-app cols="12" sm="12">
          <v-text-field
            v-model="form.cancellation_reason"
            placeholder="Cancellation Reason"
            label="Cancellation Reason*"
            required
          ></v-text-field>
        </v-col>

        <v-col data-app cols="12" sm="4">
          <v-select
            v-model="form.plan"
            :items="planItems"
            item-text="name"
            item-value="id"
            label="Plan*"
            validate-on-blur
            lazy-validation
          ></v-select>
        </v-col>
        <v-col data-app cols="12" sm="4">
          <v-text-field
            v-model="form.post_per_week"
            placeholder="Posts Per Week"
            label="Posts Per Week"
            required
          ></v-text-field>
        </v-col>
        <v-col data-app cols="12" sm="4">
          <v-text-field
            v-model="form.story_per_week"
            placeholder="Stories Per Week"
            label="Stories Per Week"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <div v-if="!isCancellationFlow">
        <!--
    -------------------------------------------------------
        Style
    -------------------------------------------------------
        -->
        <v-divider class="divider" />

        <h2 class="my-4">Style</h2>
        <br />
        <v-row>
          <v-col cols="12" sm="4">
            <v-select
              :items="optionalBrandStyles"
              v-model="form.brand_styles"
              item-text="name"
              item-value="name"
              multiple
              label="Brand Styles"
            />
          </v-col>

          <v-col data-app cols="12" sm="4">
            <v-file-input
              prepend-icon="mdi-camera"
              chips
              accept="image/png, image/jpeg, image/bmp"
              label="Headshots"
              @change="onHeadshotChange"
            ></v-file-input>

            <v-avatar v-if="brand_headshots_images" xlarge left color="#C4C4C4">
              <img :src="brand_headshots_images" />
            </v-avatar>

            <!-- <v-chip
            v-for="(headshot, index) in brand_headshots_images"
            :key="`headshot_${index}`"
            close
            @click:close="deleteHeadshot(index)"
          >
            <v-avatar xlarge left color="#C4C4C4">
              <img :src="headshot" />
            </v-avatar>
          </v-chip> -->
          </v-col>
          <v-col data-app cols="12" sm="4">
            <v-file-input
              prepend-icon="mdi-camera"
              chips
              accept="image/png, image/jpeg, image/bmp"
              label="Logo image"
              @change="onBrandLogoChange"
            ></v-file-input>
            <v-avatar v-if="brand_logo_image" color="#C4C4C4">
              <img :src="brand_logo_image" />
            </v-avatar>
          </v-col>
          <v-col data-app cols="12" sm="4">
            <v-file-input
              prepend-icon="mdi-camera"
              chips
              accept="image/png, image/jpeg, image/bmp"
              label="Square Logo image"
              @change="onSquareLogoChange"
            >
            </v-file-input>
            <img
              v-if="square_logo_image"
              :src="square_logo_image"
              height="48px"
            />
          </v-col>
          <v-col data-app cols="12" sm="4">
            <v-file-input
              prepend-icon="mdi-camera"
              chips
              accept="image/png, image/jpeg, image/bmp"
              label="Brokerage Logo image"
              @change="onBrokerageLogoChange"
            ></v-file-input>
            <v-avatar v-if="brokerage_logo_image" color="#C4C4C4">
              <img :src="brokerage_logo_image" />
            </v-avatar>
          </v-col>
          <v-col cols="12" sm="4">
            <v-autocomplete
              v-model="form.brand_font_families"
              class="small-font"
              :items="supportedFontFamilies"
              clearable
              chips
              deletable-chips
              multiple
              append-icon=""
              label="Font Families"
            >
            </v-autocomplete>
          </v-col>
          <v-col data-app cols="12" sm="4">
            <v-select
              v-model="form.filter"
              :items="filters"
              item-text="name"
              item-value="id"
              label="Filter"
              validate-on-blur
              lazy-validation
            ></v-select>
          </v-col>
          <v-col cols="12" sm="8">
            <v-text-field
              v-model="inputBrandColor"
              class="mb-3 mt-2"
              hide-details
              label="Color Patterns"
              append-icon="mdi-palette"
              @click:append="addBrandColor"
              @keydown.enter="addBrandColor"
            />
            <v-chip
              v-for="(el, index) in form.brand_color_palettes"
              :key="`modify_brand_color_palettes_${index}`"
              class="mb-2 mr-2 mt-1"
              color="blue darken-3"
              outlined
              close
              @click:close="form.brand_color_palettes.splice(index, 1)"
            >
              <span
                v-for="(hexColor, index) in el"
                :key="index"
                :style="`margin:4px; color:${
                  hexColor == '#FFFFFF' ? 'black' : 'white'
                }; background-color:${hexColor};`"
                >{{ hexColor }}</span
              >
            </v-chip>
            <v-dialog v-model="isShowColorPicker" width="800px">
              <ColorPalettes
                :hexColors="[]"
                v-if="isShowColorPicker"
                @addPalette="
                  form.brand_color_palettes.push($event);
                  isShowColorPicker = false;
                "
              ></ColorPalettes>
            </v-dialog>
          </v-col>

          <!-- <v-col cols="12" sm="4">
          <v-row dense
            ><v-avatar color="#C4C4C4">
              <img v-if="form.picUrl" :src="form.picUrl" />
            </v-avatar>
            <v-file-input
              accept="image/png, image/jpeg, image/bmp"
              label="Picture"
              @change="selectJsonFile()"
              v-model="form.picUrl"
            ></v-file-input>
          </v-row>
        </v-col> -->
        </v-row>

        <!--
    -------------------------------------------------------
        Content
    -------------------------------------------------------
        -->
        <v-divider class="divider" />

        <h2 class="section">Content</h2>
        <v-row>
          <v-col cols="12" sm="4">
            <v-select
              v-model="selectedSubdomains"
              :items="optionalSubdomainsNames"
              item-text="subdomain"
              item-value="subdomain"
              label="Subdomains"
              validate-on-blur
              lazy-validation
              multiple
            ></v-select>

            <!-- <v-text-field v-model="form.subdomains" label="Subdomains" disabled>
        </v-text-field>
        <v-checkbox
          v-for="subdomain in optionalSubdomainsNames"
          multiple
          v-model="selectedSubdomains"
          :key="subdomain"
          :value="subdomain"
          :label="subdomain"
        /> -->
          </v-col>

          <!-- <v-col data-app cols="12" sm="4">
          <ProductsSelection
            v-if="optionalFeatures.includes('products')"
            :domain="form.domain"
            :subdomains="selectedSubdomains"
            :previousSelectedProducts="form.products"
            @select="productsSelectionResults($event)"
          />
        </v-col> -->

          <v-col cols="12" sm="6" v-if="form.domain === 'ecommerce'">
            <v-checkbox
              v-model="form.is_automated"
              label="Automated Customer?"
            ></v-checkbox>
          </v-col>

          <v-col cols="12" sm="4">
            <div class="form-control hashtags">
              <span class="form-control__name">Stock-Media search-terms:</span>
              <v-combobox
                :delimiters="[',', ';']"
                @change="stockMediaSearchTermsInput"
                v-model="form.stock_media_search_terms"
                chips
                hide-details
                class="tags_container"
                label="Stock media search terms"
                multiple
                append-icon=""
                solo
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="deleteStockMediaSearchTermsHandler(item)"
                  >
                    <strong>{{ item }}</strong>
                  </v-chip>
                </template>
              </v-combobox>
            </div>
          </v-col>
          <v-col data-app cols="12" sm="4">
            <v-autocomplete
              v-model="form.categories"
              class="small-font"
              :items="optionalCategories"
              clearable
              chips
              deletable-chips
              multiple
              append-icon=""
              label="Categories"
            >
            </v-autocomplete>

            <!-- <CategoriesSelection
            :optionalCategories="optionalCategories"
            :previousSelectedCategories="initialSelectedSCategories"
            @select="categoriesSelectionResults($event)"
          /> -->
          </v-col>

          <v-col data-app cols="12" sm="4">
            <v-text-field
              v-model="form.zillow_page"
              placeholder="Zillow Page"
              label="Zillow Page"
            ></v-text-field>
          </v-col>

          <v-col data-app cols="12" sm="4">
            <v-text-field
              v-model="form.hashtags_number"
              placeholder="Total number of hashtags"
              label="Total number of hashtags"
              required
            ></v-text-field>
          </v-col>
          <v-col data-app cols="12" sm="4">
            <v-text-field
              v-model="form.template_group"
              placeholder="Template Group"
              label="Template Group"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <div class="form-control hashtags">
              <span class="form-control__name"
                >Hashtags Random({{ hashtagsHint }})</span
              >
              <v-combobox
                :delimiters="[',', ' ', ';', '#']"
                @change="hashtagsInput"
                v-model="form.hashtags"
                chips
                hide-details
                class="tags_container"
                label="Hashtags Random"
                multiple
                append-icon=""
                solo
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="deleteHashtagHandler(item)"
                  >
                    <strong>{{ item }}</strong>
                  </v-chip>
                </template>
              </v-combobox>
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <div class="form-control hashtags">
              <span class="form-control__name"
                >Hashtags Constant ({{ hashtagsConstantHint }})</span
              >
              <v-combobox
                :delimiters="[',', ' ', ';', '#']"
                @change="hashtagsConstantInput"
                v-model="form.hashtags_constant"
                chips
                hide-details
                class="tags_container"
                label="Hashtags Constant"
                multiple
                append-icon=""
                solo
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="deleteHashtagConstantHandler(item)"
                  >
                    <strong>{{ item }}</strong>
                  </v-chip>
                </template>
              </v-combobox>
            </div>
          </v-col>
          <v-col cols="12" sm="8">
            <div class="form-control">
              <span class="form-control__name"
                >Inspiration links
                {{
                  (form.inspiration_links && form.inspiration_links.length) ||
                  0
                }}/4</span
              >
              <v-combobox
                :delimiters="[',', ' ', ';']"
                @change="inspirationLinksInput"
                v-model="form.inspiration_links"
                chips
                hide-details
                class="tags_container"
                label="Inspiration links"
                multiple
                append-icon=""
                solo
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="deleteInspirationLinksHandler(item)"
                      >
                        <v-btn icon :href="item" target="_blank" v-on="on">
                          <v-icon>mdi-open-in-new</v-icon>
                        </v-btn>
                        <strong>{{ item }}</strong>
                      </v-chip>
                    </template>
                    <span style="font-size: 12px">Open in new tab</span>
                  </v-tooltip>
                </template>
              </v-combobox>
            </div>
          </v-col>

          <v-col cols="12" sm="8">
            <div class="form-control">
              <span class="form-control__name">Preferred Sites</span>
              <v-combobox
                :delimiters="[',', ' ', ';']"
                @change="preferredSitesInput"
                v-model="form.preferred_sites"
                chips
                hide-details
                class="tags_container"
                label="Preferred Site"
                multiple
                append-icon=""
                solo
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="deletePreferredSiteHandler(item)"
                      >
                        <v-btn icon :href="item" target="_blank" v-on="on">
                          <v-icon>mdi-open-in-new</v-icon>
                        </v-btn>
                        <strong>{{ item }}</strong>
                      </v-chip>
                    </template>
                    <span style="font-size: 12px">Open in new tab</span>
                  </v-tooltip>
                </template>
              </v-combobox>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.business_signature"
              placeholder="Business Signature"
              label="Business Signature"
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="12" sm="4">
          <v-text-field
            v-model="form.post_recipe"
            placeholder="post recipe"
            label="post recipe*"
            :rules="[rules.fieldMustBeWithoutSpaces]"
          ></v-text-field>
        </v-col> -->
        </v-row>
      </div>

      <v-divider class="divider" />
      <br />
      <div class="submit-wrapper">
        <v-row>
          <button @click.prevent="validate" class="submit-btn">Update</button>
          <v-spacer></v-spacer>
          <button @click.prevent="close" class="close-btn">Close</button>
        </v-row>
      </div>
      <v-dialog v-model="dialog" width="700">
        <v-card>
          <div class="form-summary">
            <div v-if="!isDiffs()">
              <div class="form-summary__title">No changes to update</div>
            </div>
            <div v-else>
              <div class="form-summary__title">
                Check the details that were modified:
              </div>
              <div class="form-summary__list">
                <div v-for="(field, index) of diffs" :key="index">
                  <span v-if="index == 'smm_id'">
                    <strong>{{ index }}: </strong>
                    {{ getSmmNamesFromIds() }}</span
                  >
                  <span v-else-if="index == 'owner_id'">
                    <strong>{{ index }}: </strong>
                    {{
                      itemsSmm.find((smm) => smm.id == field)
                        ? itemsSmm.find((smm) => smm.id == field).name
                        : ""
                    }}</span
                  >
                  <span v-else-if="index == 'postAnyway'">
                    <strong>Post Anyway: </strong>
                    {{
                      postAnyWayList.find(
                        (postAnyway) => postAnyway.id == field
                      )
                        ? postAnyWayList.find(
                            (postAnyway) => postAnyway.id == field
                          ).name
                        : ""
                    }}</span
                  >
                  <span v-else-if="index == 'brand_color_palettes'"
                    ><strong>{{ index }}: </strong> {{ field }}
                  </span>
                  <span v-else-if="Array.isArray(form[index])"
                    ><strong>{{ index }}: </strong> {{ form[index].join(", ") }}
                  </span>
                  <span v-else
                    ><strong>{{ index }}: </strong> {{ field }}
                  </span>
                </div>
              </div>
            </div>
            <v-divider class="divider" />

            <div class="form-summary__btn">
              <button
                v-if="isDiffs()"
                @click.prevent="submitForm"
                class="submit"
              >
                Submit
              </button>
              <v-spacer></v-spacer>
              <button @click.prevent="dialog = !dialog" class="cancel">
                Cancel
              </button>
            </div>
          </div>
          <div class="form-success" v-if="isSubmit">
            <div class="form-success__message">Updated</div>
          </div>
          <!-- <Loader v-if="isLoading" /> -->
        </v-card>
      </v-dialog>
    </v-form>
    <v-layout column> </v-layout>
    <br />
  </v-container>
</template>

<script>
import validationRules from "@/shared/validationRules";
import { mapActions, mapGetters, mapMutations } from "vuex";
import CopyButton from "@/components/global/CopyButton";
import ColorPalettes from "@/components/global/ColorPalettes";
import FilesSync from "@/components/global/FilesSync";
import H from "@/utils/helper";

import Loader from "@/components/global/Loader";
import {
  SUPPORTED_SOCIAL_PLATFORMS,
  SUPPORTED_BRAND_STYLES,
  SUPPORTED_TIME_ZONES,
  SUPPORTED_FILTERS,
  SEND_BY_ITEMS,
  PLANS,
  ACTIVITY_STATE,
  POST_AWAY,
  EXCLUDED_OWNERS,
  MEDIA_MODE_LIST,
  RESIDENCE,
} from "@/constants/globals";
import { TEMPLATE_FONTS } from "@/constants/globals/templateFonts.js";
// import ProductsSelection from "@/components/product/ProductsSelection";
// import CategoriesSelection from "@/components/category/CategoriesSelection";
import { diff } from "deep-object-diff";

export default {
  name: "ModifyClient",
  components: {
    Loader,
    CopyButton,
    ColorPalettes,
    FilesSync,
    // ProductsSelection,
    // CategoriesSelection,
  },
  mixins: [validationRules],

  data: () => ({
    isLoading: false,
    isCancellationFlow: false,
    customerId: null,
    clientInfo: null,
    valid: true,
    dialog: false,
    brand_logo_image: null,
    square_logo_image: null,
    brokerage_logo_image: null,
    brand_headshots_images: null,
    brand_headshots_new_files: null,
    inputBrandColor: null,
    isShowColorPicker: false,
    form: {
      domain: "",
      subdomains: [],
      name: "",
      personal_name: "",
      business_signature: "",
      primary_phone: "",
      mail: "",
      business_email: "",
      slack_email: "",
      website: "",
      social_platforms: [],
      ig_page: "",
      fb_page: "",
      post_per_week: "",
      story_per_week: "",
      address_country: "",
      address_state: "",
      address_city: "",
      address_street: "",
      post_recipe: [],
      hashtags: [],
      hashtags_constant: [],
      stock_media_search_terms: [],
      hashtags_number: 0,
      smm_id: [],
      picUrl: null,
      send_by: {},
      plan: {},
      time_zone: "",
      filter: "normal",
      about_client: "",
      about_business: "",
      permanent_export_note: "",
      files_sync: {},
      additional_phones: [],
      activity_state: null,
      preferred_sites: [],
      inspiration_links: [],
      products: [],
      categories: [],
      brand_font_families: [],
      brand_color_palettes: [],
      brand_logo: "",
      square_logo: "",
      brokerage_logo: "",
      brand_headshots: [],
      brand_styles: [],
      churn_type: "",
      cancellation_reason: "",
      stripe_state: "",
      stripe_customer_id: "",
      rush_post: false,
      is_automated: false,
      additional_emails: [],
      postAnyway: "2",
      zillow_page: "",
      template_group: "",
      profession: "",
      media_mode: "",
      residence: "",
      is_atproperties: false,
    },
    formInitial: {},
    diffs: null,
    itemsSmm: [],
    sendByiItems: SEND_BY_ITEMS,
    activityStateList: ACTIVITY_STATE,
    planItems: PLANS,
    timeZones: SUPPORTED_TIME_ZONES,
    filters: SUPPORTED_FILTERS,
    postAnyWayList: POST_AWAY,
    isSubmit: false,
    optionalFeatures: [],
    optionalCategories: [],
    supportedSocialPlatforms: SUPPORTED_SOCIAL_PLATFORMS,
    supportedFontFamilies: TEMPLATE_FONTS,
    optionalBrandStyles: SUPPORTED_BRAND_STYLES,
    selectedSubdomains: [],
    optionalSubdomains: [],
    optionalSubdomainsNames: [],
    initialSelectedSCategories: [],
    mediaModeList: MEDIA_MODE_LIST,
    residenceList: RESIDENCE,
  }),

  async created() {
    this.isLoading = true;
    this.customerId = this.$route.query && this.$route.query.customerId;

    await this.getSmmList();
    await this.fetchDomainsTree();
    await this.initCustomer();

    this.form.smm_id = this.form.smm_id.filter(function (item, pos, self) {
      return self.indexOf(item) == pos;
    });
    this.isLoading = false;
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
    hashtagsHint() {
      return `${this.form.hashtags.length}/${this.form.hashtags_number || 30}`;
    },
    hashtagsConstantHint() {
      return `${this.form.hashtags_constant.length}/${
        this.form.hashtags_number || 30
      }`;
    },
    lastId() {
      return this.form.additional_phones[this.form.additional_phones.length - 1]
        .id;
    },
    additionalPhonesList() {
      return this.form.additional_phones.length > 0
        ? this.form.additional_phones.map((i) => i.value)
        : [];
    },
  },
  watch: {
    selectedSubdomains() {
      this.form.subdomains = this.selectedSubdomains;

      let optionalFeaturesSet = new Set();
      let categoriesSet = new Set();
      this.selectedSubdomains.map((subdomain) => {
        let optionalFeaturesOfSubdomain = this.getOptionalFeatures()(
          this.form.domain,
          subdomain
        );
        if (optionalFeaturesOfSubdomain.length > 0) {
          optionalFeaturesOfSubdomain.map((f) => optionalFeaturesSet.add(f));
        }
        let categoriesOfSubdomain = this.getCategories()(
          this.form.domain,
          subdomain
        );
        if (categoriesOfSubdomain.length > 0) {
          categoriesOfSubdomain.map((cat) =>
            // categoriesSet.add({ category: cat, subdomain: subdomain, key: `${cat}-${subdomain}` })
            categoriesSet.add(cat)
          );
        }
      });
      this.optionalFeatures = [...optionalFeaturesSet];
      this.optionalCategories = [...categoriesSet];
    },
  },
  methods: {
    ...mapActions("clientSelection", [
      "getCurrentCustomer",
      "fetchCustomerInfo",
      "updateCustomer",
      "fetchCustomers",
    ]),
    ...mapMutations("clientSelection", ["setCurrentCustomer"]),
    ...mapActions("clientForm", [
      "fetchSmmList",
      "sendClientForm",
      "getUrlForSendImg",
      "uploadImage",
    ]),
    ...mapActions("filesController", ["getUrlForSendFile", "uploadFile"]),
    ...mapGetters("globals", [
      "getSubdomainsTree",
      "getDomainsTree",
      "getOptionalFeatures",
      "getPersonaParameters",
      "getCategories",
    ]),
    ...mapActions("globals", ["fetchDomainsTree"]),

    isDiffs() {
      if (!this.diffs) return false;
      return Object.keys(this.diffs).length > 0;
    },

    activityStateChanged(newState) {
      if (this.form.activity_state == "Testing" && newState == "Canceled") {
        this.isCancellationFlow = true;
      }
      this.form.activity_state = newState;
    },

    async initCustomer() {
      if (this.customerId) {
        this.clientInfo = await this.fetchCustomerInfo(this.customerId);
      }

      // await this.fillCustomerInfo();
      this.form = H.deepCopy(this.clientInfo);
      this.formInitial = H.deepCopy(this.clientInfo);

      this.brand_logo_image = this.formInitial.brand_logo;
      this.square_logo_image = this.formInitial.square_logo;
      this.brokerage_logo_image = this.formInitial.brokerage_logo;
      // this.brand_headshots_images = this.formInitial.brand_headshots || [];
      this.brand_headshots_images = this.formInitial.brand_headshots[0];
      this.brand_headshots_new_files = null;
      this.selectedSubdomains = [...this.clientInfo.subdomains];
      this.initialSelectedSCategories = [...this.form.categories];

      this.optionalSubdomains = this.getSubdomainsTree()(this.form.domain);
      this.optionalSubdomainsNames = this.optionalSubdomains.map(
        (sd) => sd.name
      );
      if (this.form.smm_id.constructor !== Array) {
        this.form.smm_id = [this.form.smm_id];
      }

      this.form.smm_id = this.form.smm_id.filter(function (item, pos, self) {
        return self.indexOf(item) == pos;
      });

      if (
        ["customer_deleted", "subscription_deleted"].includes(
          this.form.stripe_state
        ) ||
        this.form.activity_state == "Canceled"
      ) {
        this.form.activity_state = "Canceled";
        this.isCancellationFlow = true;
      }
    },

    onBrandLogoChange(file) {
      if (!file) {
        this.brand_logo_image = null;
        return;
      }
      this.form.brand_logo = file;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.brand_logo_image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    onSquareLogoChange(file) {
      if (!file) {
        this.square_logo_image = null;
        return;
      }
      this.form.square_logo = file;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.square_logo_image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    onBrokerageLogoChange(file) {
      if (!file) {
        this.brokerage_logo_image = null;
        return;
      }
      this.form.brokerage_logo = file;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.brokerage_logo_image = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    onHeadshotChange(files) {
      //FIXME - should support multiple files!
      console.log("onHeadshotChange files", files);
      if (!files) {
        this.brand_headshots_images = null;
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        this.brand_headshots_images = e.target.result;
      };
      reader.readAsDataURL(files);

      this.brand_headshots_new_files = files;
      this.form.brand_headshots = files;
    },

    addBrandColor() {
      if (this.inputBrandColor) {
        const colorsList = this.inputBrandColor.split(/[\s,;]+/);
        this.form.brand_color_palettes.push(colorsList);
        this.inputBrandColor = null;
        return;
      }
      this.isShowColorPicker = true;
    },
    hashtagsInput(val) {
      if (!val && !val.length) return;
      this.form.hashtags = H.hashtagsCompose(val);
    },
    preferredSitesInput(val) {
      if (!val && !val.length) return;
      this.form.preferred_sites = H.urlsCompose(val);
    },
    inspirationLinksInput(val) {
      if (!val && !val.length) return;
      this.form.inspiration_links = H.urlsCompose(val);
    },
    hashtagsConstantInput(val) {
      if (!val && !val.length) return;
      this.form.hashtags_constant = H.hashtagsCompose(val);
    },
    stockMediaSearchTermsInput(val) {
      if (!val && !val.length) return;
      this.form.stock_media_search_terms = val;
    },
    containsWhitespace(str) {
      return /\s/.test(str);
    },
    getSmmNamesFromIds(smmIds) {
      let names = [];

      this.form.smm_id.map((smmId) => {
        const smm = this.itemsSmm.find((smm) => smm.id == smmId);
        names.push(smm && smm.name);
      });

      return names.join(", ");
    },
    deletePreferredSiteHandler(tag) {
      this.form.preferred_sites = this.form.preferred_sites.filter(
        (val) => val !== tag
      );
    },
    deleteInspirationLinksHandler(tag) {
      this.form.inspiration_links = this.form.inspiration_links.filter(
        (val) => val !== tag
      );
    },

    // deleteHeadshot(index) {
    //   console.log("deleteHeadshot(index)", index)
    //   console.log("this.brand_headshots_images.length", this.brand_headshots_images.length)
    //   console.log("this.brand_headshots_new_files.length", this.brand_headshots_new_files.length)

    //   if (index < this.form.brand_headshots.length) {
    //     this.form.brand_headshots.splice(index, 1);
    //   }
    //   else {
    //     let files_index = index - this.form.headshot.length
    //     this.brand_headshots_new_files.splice(files_index, 1)
    //   }

    //   this.brand_headshots_images.splice(index, 1);
    // },

    deleteHashtagHandler(tag) {
      this.form.hashtags = this.form.hashtags.filter((val) => val !== tag);
    },
    deleteHashtagConstantHandler(tag) {
      this.form.hashtags_constant = this.form.hashtags_constant.filter(
        (val) => val !== tag
      );
    },
    deleteStockMediaSearchTermsHandler(tag) {
      this.form.stock_media_search_terms =
        this.form.stock_media_search_terms.filter((val) => val !== tag);
    },
    deleteAdditionalPhone(additionPhone) {
      this.form.additional_phones = this.form.additional_phones.filter(
        (val) => val !== additionPhone
      );
    },

    deleteAdditionalEmail(additionEmail) {
      this.form.additional_emails = this.form.additional_emails.filter(
        (val) => val !== additionEmail
      );
    },

    async submitForm() {
      this.isLoading = true;

      if (
        this.form.brand_logo &&
        this.formInitial.brand_logo != this.form.brand_logo
      ) {
        let newUrl = await this.attachFile(this.form.brand_logo);
        this.form.brand_logo = newUrl;
      }
      if (
        this.form.square_logo &&
        this.formInitial.square_logo != this.form.square_logo
      ) {
        let newUrl = await this.attachFile(this.form.square_logo);
        this.form.square_logo = newUrl;
      }
      if (
        this.form.brokerage_logo &&
        this.formInitial.brokerage_logo != this.form.brokerage_logo
      ) {
        let newUrl = await this.attachFile(this.form.brokerage_logo);
        this.form.brokerage_logo = newUrl;
      }

      if (this.brand_headshots_new_files) {
        // for (let new_headshot_file in this.brand_headshots_new_files) {
        let newUrl = await this.attachFile(this.brand_headshots_new_files);
        this.form.brand_headshots = [newUrl];
      }

      // console.log("submitForm");
      // console.log("this.diffs", this.diffs);
      let params = { ...this.form };
      delete params["id"];
      delete params["note"];
      delete params["facebook_token"];

      // tmporary disabled
      // if (this.diffs.picUrl) {
      //   params.picUrl = await this.attachFile(this.diffs.picUrl);
      // }

      // hack for product/Product/products...
      if (params.products) {
        params["Product"] = params["products"];
        delete params["products"];
      }

      try {
        const data = await this.updateCustomer({
          customerId: this.customerId,
          params: params,
        });

        this.dialog = false;
        this.isSubmit = false;
        this.isLoading = false;

        if (this.getCurrentUser) {
          await this.fetchCustomers({ id: this.getCurrentUser.id });
        }

        await this.initCustomer();
      } catch (e) {
        throw e;
      }
    },

    // productsSelectionResults(selectedProducts) {
    //   this.form.products = selectedProducts;
    // },
    // categoriesSelectionResults(selectedCategories) {
    //   this.form.categories = selectedCategories;
    // },
    async getSmmList() {
      try {
        const data = await this.fetchSmmList();
        if (data) {
          let mainOwnersList = data.filter(
            (owner) => !EXCLUDED_OWNERS.find((item) => item === owner.name)
          );
          let subOwnersList = data.filter((owner) =>
            EXCLUDED_OWNERS.find((item) => item === owner.name)
          );
          this.itemsSmm = [
            ...mainOwnersList.sort((a, b) => (a.name > b.name ? 1 : -1)),
            ...subOwnersList.sort((a, b) => (a.name > b.name ? 1 : -1)),
          ];
        }
      } catch (e) {
        throw e;
      }
    },
    async attachFile(file) {
      const { url, mediaId, fields } = await this.getUrlForSendFile({
        file,
        customerId: this.customerId,
        type: "customer_image",
      });
      await this.uploadFile({ url, file, fields });
      return url + fields.key;
    },
    validate() {
      let form = Object.assign(this.form);

      !form.smm_id.includes(form.owner_id)
        ? (form.smm_id = [...form.smm_id, form.owner_id])
        : null;
      // console.log(this.formInitial, form);
      this.diffs = diff(this.formInitial, form);
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.dialog = true;
      }
    },
    close() {
      this.$router.push({ name: "client-selection-page" });
    },
  },
};
</script>

<style></style>

<style scoped lang="scss">
.add-more {
  font-size: 14px;
  color: #7e5689;
  font-weight: 500;
  margin-top: 10px;
}
.section {
  margin-bottom: 20px;
}
.divider {
  border-width: thin;
  border-color: #7e5689;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
}
.submit-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  .submit-btn {
    background: #7e5689;
    border-radius: 26px;
    height: 40px;
    min-width: 200px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #fff;
    padding: 0 20px;
  }
  .close-btn {
    border: 1px solid #7e5689;
    border-radius: 26px;
    height: 40px;
    min-width: 200px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: rgb(0, 0, 0);
    padding: 0 20px;
  }
}
.client-form {
  ::v-deep {
    .v-messages__message {
      color: red;
    }
    .col-sm-6,
    .col-12 {
      padding-bottom: 0;
    }
  }
}
.form-summary {
  width: 100%;
  padding: 35px 35px;
  position: relative;
  &__title {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;
    color: #1f2325;
  }
  &__list {
    display: flex;
    flex-direction: column;
  }
  &__item {
    margin-bottom: 10px;
    display: inline-flex;
    align-items: center;
    .name {
      width: 120px;
      margin-right: 10px;
      flex-shrink: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: #1f2325;
    }
    .data {
      font-size: 14px;
      line-height: 140%;
      color: #1f2325;
    }
  }
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    button {
      margin: 0 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      height: 37px;
      min-width: 120px;
      border-radius: 16px;
      box-sizing: border-box;
      border: 1px solid transparent;
      transition: all 0.3s ease;
      &.cancel {
        border: 1px solid #7e5689;
        color: #1f2325;
        &:hover {
          background: rgba(126, 86, 137, 0.1);
        }
      }
      &.submit {
        background: #7e5689;
        color: #fff;
        &:hover {
          background: #61346e;
        }
      }
    }
  }
}
.form-success {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background: #fff;
  &__message {
    text-align: center;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;
    color: #1f2325;
  }
}
.additional-emails {
  .v-chip {
    &.error {
      color: red;
    }
  }
}
</style>
