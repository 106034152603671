export const TEMPLATE_FONTS = [
  "Abhaya Libre",
  "Adamina",
  "Alegreya",
  "Alegreya SC",
  "Aleo",
  "Alice",
  "Alike",
  "Alike Angular",
  "Almendra",
  "Almendra SC",
  "Amethysta",
  "Amiri",
  "Andada",
  "Antic Didone",
  "Antic Slab",
  "Arapey",
  "Arbutus Slab",
  "Aref Ruqaa",
  "Artifika",
  "Arvo",
  "Asar",
  "Average",
  "Balthazar",
  "Baskervville",
  "Belgrano",
  "Bellefair",
  "Benne",
  "Bentham",
  "BioRhyme",
  "BioRhyme Expanded",
  "Bitter",
  "Bodoni Moda",
  "Brawler",
  "Bree Serif",
  "Brygada 1918",
  "Buenard",
  "Caladea",
  "Cambo",
  "Cantata One",
  "Cardo",
  "Castoro",
  "Caudex",
  "Cinzel",
  "Copse",
  "Cormorant",
  "Cormorant Garamond",
  "Cormorant Infant",
  "Cormorant SC",
  "Cormorant Unicase",
  "Cormorant Upright",
  "Coustard",
  "Crete Round",
  "Crimson Pro",
  "Crimson Text",
  "Cutive",
  "DM Serif Display",
  "DM Serif Text",
  "David Libre",
  "Della Respira",
  "Domine",
  "Donegal One",
  "EB Garamond",
  "Eczar",
  "Enriqueta",
  "Esteban",
  "Fanwood Text",
  "Fasthand",
  "Fauna One",
  "Faustina",
  "Fenix",
  "Fjord One",
  "Frank Ruhl Libre",
  "Fraunces",
  "GFS Didot",
  "Gabriela",
  "Gelasio",
  "Gentium Basic",
  "Gentium Book Basic",
  "Gilda Display",
  "Glegoo",
  "Goudy Bookletter 1911",
  "Grenze",
  "Gupter",
  "Gurajada",
  "Habibi",
  "Halant",
  "Hanuman",
  "Headland One",
  "Hepta Slab",
  "Holtwood One SC",
  "IBM Plex Serif",
  "IM Fell DW Pica",
  "IM Fell DW Pica SC",
  "IM Fell Double Pica",
  "IM Fell Double Pica SC",
  "IM Fell English",
  "IM Fell English SC",
  "IM Fell French Canon",
  "IM Fell French Canon SC",
  "IM Fell Great Primer",
  "IM Fell Great Primer SC",
  "Ibarra Real Nova",
  "Imbue",
  "Inika",
  "Inknut Antiqua",
  "Inria Serif",
  "Italiana",
  "Jacques Francois",
  "Jomolhari",
  "Josefin Slab",
  "Judson",
  "Junge",
  "Kadwa",
  "Kameron",
  "Karma",
  "Kiwi Maru",
  "Kotta One",
  "Kreon",
  "Kurale",
  "Ledger",
  "Libre Baskerville",
  "Libre Caslon Display",
  "Libre Caslon Text",
  "Linden Hill",
  "Literata",
  "Lora",
  "Lusitana",
  "Lustria",
  "Maitree",
  "Manuale",
  "Marcellus",
  "Marcellus SC",
  "Markazi Text",
  "Marko One",
  "Martel",
  "Mate",
  "Mate SC",
  "Merriweather",
  "Montaga",
  "Nanum Myeongjo",
  "Neuton",
  "New Tegomin",
  "Newsreader",
  "Nokora",
  "Noticia Text",
  "Noto Serif",
  "Noto Serif JP",
  "Noto Serif KR",
  "Noto Serif SC",
  "Noto Serif TC",
  "Old Standard TT",
  "Oranienbaum",
  "Ovo",
  "PT Serif",
  "PT Serif Caption",
  "Peddana",
  "Petrona",
  "Piazzolla",
  "Playfair Display",
  "Playfair Display SC",
  "Podkova",
  "Poly",
  "Port Lligat Slab",
  "Prata",
  "Pridi",
  "Prociono",
  "Quando",
  "Quattrocento",
  "Radley",
  "Ramaraja",
  "Rasa",
  "Rhodium Libre",
  "Roboto Slab",
  "Rokkitt",
  "Rosarivo",
  "Rozha One",
  "Rufina",
  "Sahitya",
  "Sanchez",
  "Scheherazade",
  "Scope One",
  "Shippori Mincho",
  "Slabo 13px",
  "Slabo 27px",
  "Solway",
  "Song Myung",
  "Sorts Mill Goudy",
  "Source Serif Pro",
  "Spectral",
  "Spectral SC",
  "Sree Krushnadevaraya",
  "Stoke",
  "Suez One",
  "Sumana",
  "Sura",
  "Suranna",
  "Suravaram",
  "Taviraj",
  "Texturina",
  "Tienne",
  "Tinos",
  "Trirong",
  "Trocchi",
  "Trykker",
  "Ultra",
  "Unna",
  "Vesper Libre",
  "Vidaloka",
  "Volkhov",
  "Vollkorn",
  "Vollkorn SC",
  "Yrsa",
  "ZCOOL XiaoWei",
  "Zilla Slab",
  "ABeeZee",
  "Abel",
  "Aclonica",
  "Acme",
  "Actor",
  "Advent Pro",
  "Alata",
  "Alatsi",
  "Aldrich",
  "Alef",
  "Alegreya Sans",
  "Alegreya Sans SC",
  "Allerta",
  "Allerta Stencil",
  "Almarai",
  "Amaranth",
  "Amiko",
  "Anaheim",
  "Andika",
  "Andika New Basic",
  "Antic",
  "Anton",
  "Antonio",
  "Archivo",
  "Archivo Black",
  "Archivo Narrow",
  "Arimo",
  "Armata",
  "Arsenal",
  "Arya",
  "Asap",
  "Asap Condensed",
  "Assistant",
  "Asul",
  "Athiti",
  "Average Sans",
  "B612",
  "Bai Jamjuree",
  "Barlow",
  "Barlow Condensed",
  "Barlow Semi Condensed",
  "Basic",
  "Be Vietnam",
  "Belleza",
  "BenchNine",
  "Biryani",
  "Black And White Picture",
  "Black Han Sans",
  "Blinker",
  "Bubbler One",
  "Cabin",
  "Cabin Condensed",
  "Cagliostro",
  "Cairo",
  "Cambay",
  "Candal",
  "Cantarell",
  "Cantora One",
  "Capriola",
  "Carme",
  "Carrois Gothic",
  "Carrois Gothic SC",
  "Catamaran",
  "Chakra Petch",
  "Changa",
  "Chathura",
  "Chau Philomene One",
  "Chivo",
  "Commissioner",
  "Convergence",
  "Cuprum",
  "DM Sans",
  "Darker Grotesque",
  "Days One",
  "Denk One",
  "Dhurjati",
  "Didact Gothic",
  "Do Hyeon",
  "Doppio One",
  "Dorsa",
  "Dosis",
  "DotGothic16",
  "Duru Sans",
  "Economica",
  "El Messiri",
  "Electrolize",
  "Encode Sans",
  "Encode Sans Condensed",
  "Encode Sans Expanded",
  "Encode Sans Semi Condensed",
  "Encode Sans Semi Expanded",
  "Englebert",
  "Epilogue",
  "Exo",
  "Exo 2",
  "Fahkwang",
  "Farro",
  "Federo",
  "Fira Sans",
  "Fira Sans Condensed",
  "Fira Sans Extra Condensed",
  "Fjalla One",
  "Francois One",
  "Fresca",
  "GFS Neohellenic",
  "Gafata",
  "Galdeano",
  "Gayathri",
  "Geo",
  "Gidugu",
  "Gothic A1",
  "Gotu",
  "Gudea",
  "Hammersmith One",
  "Harmattan",
  "Heebo",
  "Hind",
  "Hind Guntur",
  "Hind Madurai",
  "Hind Siliguri",
  "Hind Vadodara",
  "Homenaje",
  "IBM Plex Sans",
  "IBM Plex Sans Condensed",
  "Imprima",
  "Inder",
  "Inria Sans",
  "Inter",
  "Istok Web",
  "Jaldi",
  "Jockey One",
  "Josefin Sans",
  "Jost",
  "Jua",
  "Julius Sans One",
  "Jura",
  "K2D",
  "Kanit",
  "Kantumruy",
  "Karla",
  "Khand",
  "Khula",
  "Kite One",
  "KoHo",
  "Kodchasan",
  "Kosugi",
  "Kosugi Maru",
  "Krona One",
  "Krub",
  "Kulim Park",
  "Kumbh Sans",
  "Laila",
  "Lato",
  "Lekton",
  "Lexend",
  "Lexend Deca",
  "Lexend Exa",
  "Lexend Giga",
  "Lexend Mega",
  "Lexend Peta",
  "Lexend Tera",
  "Lexend Zetta",
  "Libre Franklin",
  "Livvic",
  "M PLUS 1p",
  "M PLUS Rounded 1c",
  "Mada",
  "Magra",
  "Mako",
  "Mallanna",
  "Mandali",
  "Manjari",
  "Manrope",
  "Marmelad",
  "Martel Sans",
  "Marvel",
  "Maven Pro",
  "Meera Inimai",
  "Merriweather Sans",
  "Metrophobic",
  "Michroma",
  "Mina",
  "Miriam Libre",
  "Mitr",
  "Molengo",
  "Monda",
  "Montserrat",
  "Montserrat Alternates",
  "Montserrat Subrayada",
  "Mouse Memoirs",
  "Mukta",
  "Mukta Mahee",
  "Mukta Malar",
  "Mukta Vaani",
  "Mulish",
  "NTR",
  "Nanum Gothic",
  "News Cycle",
  "Niramit",
  "Nobile",
  "Notable",
  "Noto Sans",
  "Noto Sans HK",
  "Noto Sans JP",
  "Noto Sans KR",
  "Noto Sans SC",
  "Noto Sans TC",
  "Numans",
  "Nunito",
  "Nunito Sans",
  "Open Sans",
  "Orbitron",
  "Orienta",
  "Oswald",
  "Overpass",
  "Oxygen",
  "PT Sans",
  "PT Sans Caption",
  "PT Sans Narrow",
  "Padauk",
  "Palanquin",
  "Palanquin Dark",
  "Pathway Gothic One",
  "Pattaya",
  "Pavanam",
  "Paytone One",
  "Philosopher",
  "Play",
  "Pontano Sans",
  "Poppins",
  "Port Lligat Sans",
  "Pragati Narrow",
  "Prompt",
  "Proza Libre",
  "Public Sans",
  "Puritan",
  "Quantico",
  "Quattrocento Sans",
  "Questrial",
  "Quicksand",
  "Rajdhani",
  "Raleway",
  "Ramabhadra",
  "Rambla",
  "Rationale",
  "Recursive",
  "Red Hat Display",
  "Red Hat Text",
  "Reem Kufi",
  "Roboto",
  "Roboto Condensed",
  "RocknRoll One",
  "Ropa Sans",
  "Rosario",
  "Rubik",
  "Rubik Mono One",
  "Ruda",
  "Ruluko",
  "Rum Raisin",
  "Russo One",
  "Saira",
  "Saira Condensed",
  "Saira Extra Condensed",
  "Saira Semi Condensed",
  "Sansita",
  "Sarabun",
  "Sarala",
  "Sarpanch",
  "Sawarabi Gothic",
  "Sawarabi Mincho",
  "Scada",
  "Secular One",
  "Sen",
  "Seymour One",
  "Shanti",
  "Share Tech",
  "Signika",
  "Signika Negative",
  "Sintony",
  "Six Caps",
  "Snippet",
  "Sora",
  "Source Sans Pro",
  "Space Grotesk",
  "Spartan",
  "Spinnaker",
  "Stick",
  "Strait",
  "Stylish",
  "Sulphur Point",
  "Syncopate",
  "Syne",
  "Tajawal",
  "Tauri",
  "Teko",
  "Telex",
  "Tenali Ramakrishna",
  "Tenor Sans",
  "Text Me One",
  "Thasadith",
  "Timmana",
  "Titillium Web",
  "Tomorrow",
  "Trispace",
  "Truculenta",
  "Ubuntu",
  "Ubuntu Condensed",
  "Varela",
  "Varela Round",
  "Varta",
  "Viga",
  "Voltaire",
  "Wendy One",
  "Wire One",
  "Work Sans",
  "Yanone Kaffeesatz",
  "Yantramanav",
  "Yusei Magic",
  "Aguafina Script",
  "Aladin",
  "Alex Brush",
  "Allura",
  "Amatic SC",
  "Amita",
  "Annie Use Your Telescope",
  "Architects Daughter",
  "Arizonia",
  "Bad Script",
  "Ballet",
  "Berkshire Swash",
  "Beth Ellen",
  "Bilbo",
  "Bilbo Swash Caps",
  "Bonbon",
  "Butterfly Kids",
  "Calligraffitti",
  "Caveat",
  "Caveat Brush",
  "Cedarville Cursive",
  "Charm",
  "Charmonman",
  "Chilanka",
  "Clicker Script",
  "Comic Neue",
  "Coming Soon",
  "Condiment",
  "Cookie",
  "Courgette",
  "Covered By Your Grace",
  "Crafty Girls",
  "Damion",
  "Dancing Script",
  "Dawning of a New Day",
  "Dekko",
  "Delius",
  "Delius Swash Caps",
  "Delius Unicase",
  "Devonshire",
  "Dokdo",
  "Dr Sugiyama",
  "Eagle Lake",
  "East Sea Dokdo",
  "Engagement",
  "Euphoria Script",
  "Felipa",
  "Fondamento",
  "Gaegu",
  "Gamja Flower",
  "Give You Glory",
  "Gloria Hallelujah",
  "Gochi Hand",
  "Grand Hotel",
  "Great Vibes",
  "Hachi Maru Pop",
  "Handlee",
  "Herr Von Muellerhoff",
  "Hi Melody",
  "Homemade Apple",
  "Indie Flower",
  "Italianno",
  "Itim",
  "Jim Nightshade",
  "Julee",
  "Just Another Hand",
  "Just Me Again Down Here",
  "Kalam",
  "Kaushan Script",
  "Kavivanar",
  "Kristi",
  "La Belle Aurore",
  "Lakki Reddy",
  "Lateef",
  "League Script",
  "Leckerli One",
  "Liu Jian Mao Cao",
  "Long Cang",
  "Loved by the King",
  "Lovers Quarrel",
  "Ma Shan Zheng",
  "Mali",
  "Mansalva",
  "Marck Script",
  "Meddon",
  "Meie Script",
  "Merienda",
  "Merienda One",
  "Miss Fajardose",
  "Monsieur La Doulaise",
  "Montez",
  "Mr Bedfort",
  "Mr Dafoe",
  "Mr De Haviland",
  "Mrs Saint Delafield",
  "Mrs Sheppards",
  "Nanum Brush Script",
  "Nanum Pen Script",
  "Nerko One",
  "Neucha",
  "Niconne",
  "Norican",
  "Nothing You Could Do",
  "Over the Rainbow",
  "Pacifico",
  "Pangolin",
  "Parisienne",
  "Patrick Hand",
  "Patrick Hand SC",
  "Permanent Marker",
  "Petit Formal Script",
  "Pinyon Script",
  "Princess Sofia",
  "Quintessential",
  "Qwigley",
  "Rancho",
  "Redressed",
  "Reenie Beanie",
  "Rochester",
  "Rock Salt",
  "Romanesco",
  "Rouge Script",
  "Ruge Boogie",
  "Ruthie",
  "Sacramento",
  "Satisfy",
  "Schoolbell",
  "Sedgwick Ave",
  "Sedgwick Ave Display",
  "Shadows Into Light",
  "Shadows Into Light Two",
  "Short Stack",
  "Sofia",
  "Sriracha",
  "Stalemate",
  "Sue Ellen Francisco",
  "Sunshiney",
  "Swanky and Moo Moo",
  "Tangerine",
  "The Girl Next Door",
  "Tillana",
  "Vibur",
  "Waiting for the Sunrise",
  "Walter Turncoat",
  "Yellowtail",
  "Yesteryear",
  "Zeyada",
  "Zhi Mang Xing",
  "Abhaya Libre",
  "Advent Pro",
  "Akaya Kanadaka",
  "Akaya Telivigala",
  "Alata",
  "Alatsi",
  "Alef",
  "Alegreya",
  "Alegreya SC",
  "Alegreya Sans",
  "Alegreya Sans SC",
  "Alfa Slab One",
  "Alice",
  "Almarai",
  "Amatic SC",
  "Amiko",
  "Amiri",
  "Amita",
  "Andika",
  "Andika New Basic",
  "Angkor",
  "Anonymous Pro",
  "Anton",
  "Archivo",
  "Archivo Narrow",
  "Aref Ruqaa",
  "Arima Madurai",
  "Arimo",
  "Arsenal",
  "Arya",
  "Asap",
  "Asap Condensed",
  "Asar",
  "Assistant",
  "Athiti",
  "Atma",
  "Bad Script",
  "Bahianita",
  "Bai Jamjuree",
  "Ballet",
  "Baloo 2",
  "Baloo Bhai 2",
  "Baloo Bhaina 2",
  "Baloo Chettan 2",
  "Baloo Da 2",
  "Baloo Paaji 2",
  "Baloo Tamma 2",
  "Baloo Tammudu 2",
  "Baloo Thambi 2",
  "Balsamiq Sans",
  "Bangers",
  "Barlow",
  "Barlow Condensed",
  "Barlow Semi Condensed",
  "Barriecito",
  "Battambang",
  "Bayon",
  "Be Vietnam",
  "Bellefair",
  "Bellota",
  "Bellota Text",
  "Benne",
  "Bevan",
  "Big Shoulders Display",
  "Big Shoulders Inline Display",
  "Big Shoulders Inline Text",
  "Big Shoulders Stencil Display",
  "Big Shoulders Stencil Text",
  "Big Shoulders Text",
  "Biryani",
  "Bitter",
  "Black And White Picture",
  "Black Han Sans",
  "Bokor",
  "Brygada 1918",
  "Bungee",
  "Bungee Hairline",
  "Bungee Inline",
  "Bungee Outline",
  "Bungee Shade",
  "Cabin",
  "Cabin Condensed",
  "Cairo",
  "Calistoga",
  "Cambay",
  "Cardo",
  "Catamaran",
  "Caudex",
  "Caveat",
  "Chakra Petch",
  "Changa",
  "Charm",
  "Charmonman",
  "Chathura",
  "Chenla",
  "Chilanka",
  "Chonburi",
  "Coiny",
  "Comfortaa",
  "Commissioner",
  "Content",
  "Cormorant",
  "Cormorant Garamond",
  "Cormorant Infant",
  "Cormorant SC",
  "Cormorant Unicase",
  "Cormorant Upright",
  "Cousine",
  "Crimson Pro",
  "Cuprum",
  "Cute Font",
  "Dancing Script",
  "Dangrek",
  "Darker Grotesque",
  "David Libre",
  "Dekko",
  "Dela Gothic One",
  "Dhurjati",
  "Didact Gothic",
  "Do Hyeon",
  "Dokdo",
  "Dosis",
  "DotGothic16",
  "EB Garamond",
  "East Sea Dokdo",
  "Eczar",
  "El Messiri",
  "Encode Sans",
  "Encode Sans Condensed",
  "Encode Sans Expanded",
  "Encode Sans Semi Condensed",
  "Encode Sans Semi Expanded",
  "Epilogue",
  "Exo",
  "Exo 2",
  "Fahkwang",
  "Farsan",
  "Fasthand",
  "Faustina",
  "Fira Code",
  "Fira Mono",
  "Fira Sans",
  "Fira Sans Condensed",
  "Fira Sans Extra Condensed",
  "Forum",
  "Francois One",
  "Frank Ruhl Libre",
  "Fraunces",
  "Freehand",
  "GFS Didot",
  "GFS Neohellenic",
  "Gabriela",
  "Gaegu",
  "Galada",
  "Gamja Flower",
  "Gayathri",
  "Gelasio",
  "Gidugu",
  "Glegoo",
  "Goldman",
  "Gothic A1",
  "Gotu",
  "Grandstander",
  "Grenze",
  "Grenze Gotisch",
  "Gugi",
  "Gurajada",
  "Hachi Maru Pop",
  "Halant",
  "Hanuman",
  "Harmattan",
  "Heebo",
  "Hepta Slab",
  "Hi Melody",
  "Hind",
  "Hind Guntur",
  "Hind Madurai",
  "Hind Siliguri",
  "Hind Vadodara",
  "IBM Plex Mono",
  "IBM Plex Sans",
  "IBM Plex Sans Condensed",
  "IBM Plex Serif",
  "Imbue",
  "Inconsolata",
  "Inknut Antiqua",
  "Inter",
  "Istok Web",
  "Itim",
  "Jaldi",
  "JetBrains Mono",
  "Jomhuria",
  "Jomolhari",
  "Josefin Sans",
  "Jost",
  "Jua",
  "Judson",
  "Jura",
  "K2D",
  "Kadwa",
  "Kalam",
  "Kanit",
  "Kantumruy",
  "Karantina",
  "Karma",
  "Katibeh",
  "Kavivanar",
  "Kdam Thmor",
  "Kelly Slab",
  "Khand",
  "Khmer",
  "Khula",
  "Kirang Haerang",
  "Kiwi Maru",
  "KoHo",
  "Kodchasan",
  "Kosugi",
  "Kosugi Maru",
  "Koulen",
  "Krub",
  "Kufam",
  "Kumar One",
  "Kumar One Outline",
  "Kurale",
  "Laila",
  "Lakki Reddy",
  "Lalezar",
  "Langar",
  "Lateef",
  "Ledger",
  "Lemonada",
  "Lexend",
  "Lexend Deca",
  "Lexend Exa",
  "Lexend Giga",
  "Lexend Mega",
  "Lexend Peta",
  "Lexend Tera",
  "Lexend Zetta",
  "Libre Franklin",
  "Literata",
  "Liu Jian Mao Cao",
  "Livvic",
  "Lobster",
  "Long Cang",
  "Lora",
  "M PLUS 1p",
  "M PLUS Rounded 1c",
  "Ma Shan Zheng",
  "Mada",
  "Maitree",
  "Major Mono Display",
  "Mali",
  "Mallanna",
  "Mandali",
  "Manjari",
  "Manrope",
  "Manuale",
  "Marck Script",
  "Markazi Text",
  "Marmelad",
  "Martel",
  "Martel Sans",
  "Maven Pro",
  "Meera Inimai",
  "Merriweather",
  "Merriweather Sans",
  "Metal",
  "Metrophobic",
  "Mina",
  "Miriam Libre",
  "Mirza",
  "Mitr",
  "Modak",
  "Mogra",
  "Monda",
  "Montserrat",
  "Montserrat Alternates",
  "Moul",
  "Moulpali",
  "Mukta",
  "Mukta Mahee",
  "Mukta Malar",
  "Mukta Vaani",
  "Mulish",
  "MuseoModerno",
  "NTR",
  "Nanum Brush Script",
  "Nanum Gothic",
  "Nanum Gothic Coding",
  "Nanum Myeongjo",
  "Nanum Pen Script",
  "Neucha",
  "New Tegomin",
  "Newsreader",
  "Niramit",
  "Nokora",
  "Noticia Text",
  "Noto Sans",
  "Noto Sans HK",
  "Noto Sans JP",
  "Noto Sans KR",
  "Noto Sans SC",
  "Noto Sans TC",
  "Noto Serif",
  "Noto Serif JP",
  "Noto Serif KR",
  "Noto Serif SC",
  "Noto Serif TC",
  "Nova Mono",
  "Nunito",
  "Nunito Sans",
  "Odor Mean Chey",
  "Oi",
  "Old Standard TT",
  "Open Sans",
  "Oranienbaum",
  "Orelega One",
  "Oswald",
  "PT Mono",
  "PT Sans",
  "PT Sans Caption",
  "PT Sans Narrow",
  "PT Serif",
  "PT Serif Caption",
  "Pacifico",
  "Padauk",
  "Palanquin",
  "Palanquin Dark",
  "Pangolin",
  "Patrick Hand",
  "Patrick Hand SC",
  "Pattaya",
  "Pavanam",
  "Paytone One",
  "Peddana",
  "Petrona",
  "Philosopher",
  "Piazzolla",
  "Play",
  "Playfair Display",
  "Playfair Display SC",
  "Podkova",
  "Poiret One",
  "Poor Story",
  "Poppins",
  "Potta One",
  "Pragati Narrow",
  "Prata",
  "Preahvihear",
  "Press Start 2P",
  "Pridi",
  "Prompt",
  "Prosto One",
  "Questrial",
  "Quicksand",
  "Rajdhani",
  "Rakkas",
  "Raleway",
  "Ramabhadra",
  "Ramaraja",
  "Ranga",
  "Rasa",
  "Ravi Prakash",
  "Recursive",
  "Red Rose",
  "Reem Kufi",
  "Reggae One",
  "Rhodium Libre",
  "Roboto",
  "Roboto Condensed",
  "Roboto Mono",
  "Roboto Slab",
  "RocknRoll One",
  "Rokkitt",
  "Rosario",
  "Rowdies",
  "Rozha One",
  "Rubik",
  "Rubik Mono One",
  "Ruda",
  "Ruslan Display",
  "Russo One",
  "Sahitya",
  "Saira",
  "Saira Condensed",
  "Saira Extra Condensed",
  "Saira Semi Condensed",
  "Saira Stencil One",
  "Sansita Swashed",
  "Sarabun",
  "Sarala",
  "Sarpanch",
  "Sawarabi Gothic",
  "Sawarabi Mincho",
  "Scada",
  "Scheherazade",
  "Secular One",
  "Sedgwick Ave",
  "Sedgwick Ave Display",
  "Seymour One",
  "Shippori Mincho",
  "Shrikhand",
  "Siemreap",
  "Sigmar One",
  "Signika",
  "Single Day",
  "Song Myung",
  "Source Code Pro",
  "Source Sans Pro",
  "Source Serif Pro",
  "Space Grotesk",
  "Space Mono",
  "Spectral",
  "Spectral SC",
  "Sree Krushnadevaraya",
  "Sriracha",
  "Srisakdi",
  "Stalinist One",
  "Stick",
  "Stylish",
  "Suez One",
  "Sumana",
  "Sura",
  "Suranna",
  "Suravaram",
  "Suwannaphum",
  "Tajawal",
  "Taprom",
  "Taviraj",
  "Teko",
  "Tenali Ramakrishna",
  "Tenor Sans",
  "Texturina",
  "Thasadith",
  "Tillana",
  "Timmana",
  "Tinos",
  "Train One",
  "Trirong",
  "Trispace",
  "Truculenta",
  "Ubuntu",
  "Ubuntu Condensed",
  "Ubuntu Mono",
  "Underdog",
  "VT323",
  "Varela Round",
  "Varta",
  "Vesper Libre",
  "Viaoda Libre",
  "Vibes",
  "Vollkorn",
  "Vollkorn SC",
  "Work Sans",
  "Xanh Mono",
  "Yanone Kaffeesatz",
  "Yantramanav",
  "Yatra One",
  "Yeon Sung",
  "Yeseva One",
  "Yusei Magic",
  "ZCOOL KuaiLe",
  "ZCOOL QingKe HuangYou",
  "ZCOOL XiaoWei",
  "Zhi Mang Xing",
  "bb_custom_font_compassseriftext_regular_otf",
  "bb_custom_font_compasssans_desktop_regular_otf",
  "bb_custom_font_sofiapro-bold_otf",
  "bb_custom_font_sofiapro-light_otf",
  "bb_custom_font_cmunrm_woff",
  "bb_custom_font_cmunti_woff",
  "bb_custom_font_cmunbx_woff",
  "bb_custom_font_cmunbi_woff",
  "bb_custom_font_gistesy_otf",
  "bb_custom_font_centrano2-thinitalic_woff",
  "bb_custom_font_centrano2-thin_woff",
  "bb_custom_font_centrano2-mediumitalic_woff",
  "bb_custom_font_centrano2-medium_woff",
  "bb_custom_font_centrano2-lightitalic_woff",
  "bb_custom_font_centrano2-light_woff",
  "bb_custom_font_centrano2-hairlineitalic_woff",
  "bb_custom_font_centrano2-hairline_woff",
  "bb_custom_font_centrano2-extrabolditalic_woff",
  "bb_custom_font_centrano2-extrabold_woff",
  "bb_custom_font_centrano2-bookitalic_woff",
  "bb_custom_font_centrano2-book_woff",
  "bb_custom_font_centrano2-bolditalic_woff",
  "bb_custom_font_centrano2-bold_woff",
  "bb_custom_font_centrano2-blackitalic_woff",
  "bb_custom_font_centrano2-black_woff",
  "bb_custom_font_centrano1-thinitalic_woff",
  "bb_custom_font_centrano1-thin_woff",
  "bb_custom_font_centrano1-mediumitalic_woff",
  "bb_custom_font_centrano1-medium_woff",
  "bb_custom_font_centrano1-lightitalic_woff",
  "bb_custom_font_centrano1-light_woff",
  "bb_custom_font_centrano1-hairlineitalic_woff",
  "bb_custom_font_centrano1-hairline_woff",
  "bb_custom_font_centrano1-extrabolditalic_woff",
  "bb_custom_font_centrano1-extrabold_woff",
  "bb_custom_font_centrano1-bookitalic_woff",
  "bb_custom_font_centrano1-book_woff",
  "bb_custom_font_centrano1-bolditalic_woff",
  "bb_custom_font_centrano1-bold_woff",
  "bb_custom_font_centrano1-blackitalic_woff",
  "bb_custom_font_centrano1-black_woff",
  "bb_custom_font_brittanysignature_woff",
  "bb_custom_font_neue_haas_grotesk_display_pro_65_medium_otf",
  "bb_custom_font_glacialindifference-regular_otf",
  "bb_custom_font_glacialindifference-bold_otf",
  "bb_custom_font_trajana_sans_otf",
  "bb_custom_font_trajana_sans_light_otf",
];
