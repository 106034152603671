<template>
  <v-card>
    <v-container class="ma-2 pa-2">
      <v-row class="ma-2" align="center" justify="center">
        <v-col cols="5">
          <v-color-picker
            v-model="newColor"
            dot-size="25"
            elevation="10"
            show-swatches
            swatches-max-height="196"
          ></v-color-picker>
        </v-col>
        <v-col cols="7" class="colors">
          <div class="colors__list">
            <v-chip
              v-for="(item, index) in newHexColorsList"
              :key="index"
              class="mb-2 mr-2 mt-1"
              :color="item"
              text-color="white"
              close
              @click:close="newHexColorsList.splice(index, 1)"
            >
              <span
                v-if="item === '#FFFFFF'"
                :style="`background-color: #fff; color: #000`"
                class="mx-1"
              >
                {{ item }}
              </span>
              <span
                v-else
                class="mx-1"
                :style="`color:white; background-color:${item};`"
                >{{ item }}</span
              >
            </v-chip>
          </div>
          <div class="colors__add">
            <v-btn @click="addColor(newColor.hex)"> Add </v-btn>
            <v-btn @click="addPrimaryColor(newColor.hex)">
              Primary Color
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="danger"
          text
          @click="$emit('addPalette', newHexColorsList)"
          >Save</v-btn
        >
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "ColorPalettes",
  props: {
    hexColors: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    newColor: null,
    newHexColorsList: ["#000000", "#FFFFFF"],
  }),
  methods: {
    addColor(color) {
      this.newHexColorsList.push(color);
    },
    addPrimaryColor(color) {
      this.newHexColorsList.unshift(color);
    },
    // addPrimaryColor(color) {
    //   this.newHexColorsList.forEach((item, index) => {
    //     if (item.type === "main") {
    //       this.newHexColorsList[index].type = "secondary";
    //     }
    //   });
    //   this.newHexColorsList.unshift({
    //     id: color.replace("#", ""),
    //     color: color,
    //     type: "main",
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-chip.v-size--default {
    border: 1px solid #000000 !important;
  }
}
.colors {
  &__list {
    margin-bottom: 20px;
  }
  &__add {
    .v-btn {
      margin-right: 20px;
    }
  }
}
</style>
